import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PostList from "../components/PostList";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import { filterPostsContainingTerm } from "../shared/utils/postHelpers";

const TagPageTemplate = ({ location, pageContext: { tag }, data }) => {
  const { title } = useSiteMetadata();
  const posts = filterPostsContainingTerm(data.allMdx.nodes, tag);

  return (
    <Layout location={location} title={title}>
      <div>
        <PostList posts={posts} title={`Posts tagged with "${tag}"`} iconLabel={false} />
      </div>
    </Layout>
  );
};

export default TagPageTemplate;

export const query = graphql`
  query TAG_PAGE_QUERY($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          tags
          date
          icon
        }
      }
    }
  }
`;
